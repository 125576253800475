import React, { FC } from 'react';
import { pxRem, RichText } from '@lib';
import styled from 'styled-components';
import {
    IsDesktop,
    IsMobile,
    media,
    StrapiListItemFromRes,
    Box
} from '@sanch941/lib';

export const Card: FC<ComponentProps> = ({
    idx,
    rich_name,
    rich_description
}) => {
    const DescriptionText = () => (
        <RichText
            ta="center"
            fz={16}
            lh={19}
            sm={{ fz: 18, lh: 21 }}
            text={rich_description}
        />
    );

    return (
        <StyledContainer>
            <RichText
                fz={24}
                lh={28}
                $color="#450ABE"
                ta="center"
                fw={700}
                text={rich_name}
            />

            <IsMobile>
                <Box $top={15}>
                    <DescriptionText />
                </Box>

                {idx !== 0 && <ExtraElements />}
            </IsMobile>

            <IsDesktop>
                <StyledDesktopTextContainer>
                    <DescriptionText />
                </StyledDesktopTextContainer>

                <ExtraElements />
            </IsDesktop>
        </StyledContainer>
    );
};

interface ComponentProps extends StrapiListItemFromRes {
    idx: number;
}

const ExtraElements = () => (
    <>
        <StyledLine />
        <StyledCircle />
    </>
);

const StyledLine = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 100%;
    height: ${pxRem(30)};
    width: ${pxRem(6)};
    background: linear-gradient(90deg, #470bbe -0.26%, #5c0691 100.26%);

    ${media.md} {
        bottom: calc(90% + ${pxRem(6)});
        left: 0;
        right: 0;
        margin: auto;
        width: 140%;
        transform: none;
        transform: translateX(-15%);
        height: ${pxRem(7)};
    }
`;

const StyledContainer = styled.div`
    background: #ffffff;
    box-shadow: 0px 15px 40px rgba(51, 23, 94, 0.2);
    border-radius: 20px;
    min-height: ${pxRem(150)};
    padding: ${pxRem(25)};
    position: relative;

    &:not(:first-child) {
        margin-top: ${pxRem(30)};
    }

    ${media.md} {
        min-height: ${pxRem(80)};
        width: ${pxRem(287)};
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        &:not(:first-child) {
            margin-top: 0;
            margin-left: ${pxRem(17)};
        }

        &:first-child {
            ${StyledLine} {
                right: 0;
                left: auto;
                width: 50%;
                transform: none;
            }
        }

        &:last-child {
            ${StyledLine} {
                left: 0;
                right: auto;
                width: 50%;
                transform: none;
            }
        }
    }
`;

const StyledCircle = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 95%;
    height: ${pxRem(20)};
    width: ${pxRem(20)};
    background: #5209a8;
    border-radius: 100%;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        height: ${pxRem(10)};
        width: ${pxRem(10)};
        background-color: white;
        border-radius: 100%;
    }

    ${media.md} {
        height: ${pxRem(27)};
        width: ${pxRem(27)};
        bottom: 85%;

        &::after {
            height: ${pxRem(19)};
            width: ${pxRem(19)};
        }
    }
`;

const StyledDesktopTextContainer = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    transform: translateY(${pxRem(20)});
`;
