import React from 'react';
import { IsDesktop, IsMobile } from '@sanch941/lib';
import { Container } from '@ui';
import { Desktop } from './desktop';
import { Mobile } from './mobile';

export const GalleryTemplate = () => {
    return (
        <>
            <IsMobile>
                <Mobile />
            </IsMobile>

            <IsDesktop>
                <Container>
                    <Desktop />
                </Container>
            </IsDesktop>
        </>
    );
};
