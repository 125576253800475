import React from 'react';
import { pxRem } from '@lib';
import { Box, media, StrapiListItemFromRes } from '@sanch941/lib';
import { Container } from '@ui';
import { Card } from './card';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';

export const DatesTemplate = () => {
    const { t } = useTranslation();

    const dates: StrapiListItemFromRes[] = t('about.dates');

    return (
        <StyledContainer>
            <Container>
                <Box sm={{ $flex: true, jc: 'center' }}>
                    {dates.map((date, idx) => (
                        <Card key={idx} {...date} idx={idx} />
                    ))}
                </Box>
            </Container>
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    padding: ${pxRem(50)} 0;
    background-color: #f3f3f3;

    ${media.md} {
        padding: 0;
        height: ${pxRem(268)};
        padding-top: ${pxRem(55)};
    }
`;
