import React from 'react';
import { Box } from '@sanch941/lib';
import { Description } from './description';
import { DynamicBg } from '@ui';
import first from '@assets/images/gallery/mobile/1.png';
import second from '@assets/images/gallery/mobile/2.png';
import third from '@assets/images/gallery/mobile/3.png';

export const Mobile = () => {
    return (
        <>
            <Box $flex jc="space-between" $width="100%">
                <Box $width={gap}>
                    <Description />
                </Box>

                <Box $width={gap} $flex fxd="column" jc="space-between">
                    <Box $height={gap}>
                        <DynamicBg mobile={first} />
                    </Box>

                    <Box $height={gap}>
                        <DynamicBg mobile={second} />
                    </Box>
                </Box>
            </Box>

            <Box $top={3} $height={223} $width="100%">
                <DynamicBg mobile={third} />
            </Box>

            {/* <Box $top={3} $height={223} $width="100%">
                <DynamicBg mobile={fourth} />
            </Box> */}
        </>
    );
};

const gap = '49.6%';
