import { media, pxRem } from '@sanch941/lib';
import React from 'react';
import styled from 'styled-components';

export const Map = () => {
    return (
        <StyledContainer>
            <iframe
                src="https://yandex.ru/map-widget/v1/?um=constructor%3Aac7836e8f202da14ca16d2056782ec590af58e257a9fde4678150048c5695b40&amp;source=constructor;scroll=false"
                width="100%"
                height="100%"
                frameBorder="0"
            ></iframe>
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    height: ${pxRem(342)};
    width: 100%;

    ${media.md} {
        order: 2;
        flex: 1;
        height: 100%;
    }
`;
