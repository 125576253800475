import React from 'react';
import { CommonTemplate } from '@ui';
import { ContactUsTemplate } from './templates/contact-us/contact-us';
import { DatesTemplate } from './templates/dates/dates';
import { GalleryTemplate } from './templates/gallery/gallery';
import { WelcomeTemplate } from './templates/welcome';

export const AboutPage = () => {
    return (
        <CommonTemplate>
            <WelcomeTemplate />
            <DatesTemplate />
            <GalleryTemplate />
            <ContactUsTemplate />
        </CommonTemplate>
    );
};
