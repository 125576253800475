import React from 'react';
import { Box } from '@sanch941/lib';
import { Description } from './description';
import { DynamicBg } from '@ui';
import first from '@assets/images/gallery/desktop/1.png';
import second from '@assets/images/gallery/desktop/2.png';
import third from '@assets/images/gallery/desktop/3.png';

export const Desktop = () => {
    return (
        <Box $top={70}>
            <Box $flex jc="space-between" $width="100%" $height={658}>
                <Box
                    $height="100%"
                    $flex
                    jc="space-between"
                    fxw="wrap"
                    $width="66.1%"
                >
                    <Box $height={gap} $width={gap}>
                        <Description />
                    </Box>

                    <Box $height={gap} $width={gap}>
                        <DynamicBg desktop={first} />
                    </Box>

                    <Box $top={10.5} $height={gap} $width="100%">
                        <DynamicBg desktop={second} />
                    </Box>
                </Box>
                <Box $width="32.8%" $height="100%">
                    <DynamicBg desktop={third} />
                </Box>
            </Box>

            {/* <Box $top={13} $width="100%" $height={320}>
                <DynamicBg desktop={fourth} />
            </Box> */}
        </Box>
    );
};

const gap = '49.2%';
