import React from 'react';
import { Container } from '@ui';
import { Box } from '@sanch941/lib';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { RichText } from '@lib';
import styled from 'styled-components';
import about from '@assets/images/about/about.png';

export const WelcomeTemplate = () => {
    const { t } = useTranslation();
    return (
        <Box $top={36} $bottom={21}>
            <Container>
                <Box sm={{ $flex: true, ai: 'center', jc: 'center' }}>
                    <StyledLogo src={about} />
                    <Box $top={30} sm={{ $top: 0, $left: 48 }}>
                        <RichText
                            $color="#626262"
                            fz={14}
                            lh={16}
                            sm={{ $width: '90%' }}
                            text={t('about.richWelcomeDescription')}
                        />
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

const StyledLogo = styled.img`
    margin: auto;
`;
