import React from 'react';
import { pxRem, RichText } from '@lib';
import { media, Text, Box } from '@sanch941/lib';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';

export const Description = () => {
    const { t } = useTranslation();
    return (
        <StyledContainer>
            <Text
                $color="white"
                fw={700}
                fz={32}
                lh={38}
                sm={{
                    fz: 36,
                    lh: 42
                }}
            >
                {t('about.team.title')}
            </Text>

            <Box $top={20}>
                <RichText
                    fz={13}
                    lh={15}
                    $color="rgba(255,255,255,0.7)"
                    sm={{
                        fz: 18,
                        lh: 21
                    }}
                    text={t('about.team.rich_description')}
                />
            </Box>
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    background: linear-gradient(86.74deg, #2d0877 1.54%, #1d0e32 100%);
    width: 100%;
    height: ${pxRem(338)};
    padding: ${pxRem(30)} ${pxRem(13)};

    ${media.md} {
        height: 100%;

        padding: ${pxRem(34)} ${pxRem(31)} 0;
    }
`;
