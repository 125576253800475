import React, { FC } from 'react';
import { Box, media, pxRem, Text } from '@sanch941/lib';
import { DynamicBg } from '@ui';
import { useTranslation } from 'react-i18next';
import { RichText } from '@lib';
import styled from 'styled-components';
import linksMobile from '@assets/images/about/links-mobile.png';

export const Links = () => {
    const { t } = useTranslation();

    return (
        <StyledContainer mobile={linksMobile}>
            <Section title={t('footer.techSupportEmail.title')}>
                <StyledGrayText
                    {...fz16lh19}
                    as="a"
                    href={'footer.techSupportEmail.href'}
                >
                    {t('footer.techSupportEmail.link_text')}
                </StyledGrayText>
            </Section>

            <Box $top={20}>
                <Section title={t('footer.commonQuestionsEmail.title')}>
                    <StyledGrayText
                        {...fz16lh19}
                        as="a"
                        href={t('footer.commonQuestionsEmail.href')}
                    >
                        {t('footer.commonQuestionsEmail.link_text')}
                    </StyledGrayText>
                </Section>
            </Box>

            <Box $top={20}>
                <Section title={`${t('our_address')}:`}>
                    <StyledGrayRichText
                        text={t('footer.rich_address')}
                        {...fz16lh19}
                    />
                </Section>
            </Box>
        </StyledContainer>
    );
};

const Section: FC<SectionProps> = ({ title, children }) => (
    <>
        <Text> {title} </Text>
        <Box $top={10}>{children}</Box>
    </>
);

interface SectionProps {
    title: string;
}

const fz16lh19 = {
    fz: 16,
    lh: 19
};

const StyledContainer = styled(DynamicBg)`
    padding: ${pxRem(30)} ${pxRem(13)};
    color: white;

    ${media.md} {
        height: 100%;
        width: ${pxRem(375)};
        align-self: stretch;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 ${pxRem(43)} 0 ${pxRem(33)};
    }
`;

const StyledGrayText = styled(Text)`
    opacity: 0.7;
`;

const StyledGrayRichText = styled(RichText)`
    opacity: 0.7;
`;
