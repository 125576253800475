import React from 'react';
import { Box } from '@sanch941/lib';
import { Container, SectionTitle } from '@ui';
import { Links } from './links';
import { Map } from './map';
import { useTranslation } from 'gatsby-plugin-react-i18next';

export const ContactUsTemplate = () => {
    const { t } = useTranslation();

    return (
        <Container mobilePadding={false}>
            <Box $top={40} sm={{ $top: 100, $bottom: 70 }}>
                <SectionTitle> {t('about.contactUsTitle')} </SectionTitle>

                <Box $top={30} sm={{ $top: 50, $flex: true, $height: 381 }}>
                    <Map />
                    <Links />
                </Box>
            </Box>
        </Container>
    );
};
